.section_top{
    position: relative;
    background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.4));
}
.section_top::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height:100%;
    background-size: cover;
    background-position: center;
    animation: change 10s infinite ease-in-out;
    z-index: -1;
}

.content
{
    position: relative;
    z-index: 1;
}
.applybtn {
    margin-right: 0.8rem;
    /* min-width: 5rem; */
    /* width: 100%;
    height: 100%; */
    padding: 0.3rem 0.8rem;
    border: none;
    background: transparent;
    color: rgb(65, 98, 168);
    border-radius: 8px;
    font-family: "Poppins";
    border: 1.5px solid rgb(65, 98, 168);
    font-weight: 800;
    cursor: pointer;
   
  }
@media only screen and (max-width: 600px) {
    .Invisible2{
        visibility:collapse;
    }
  }
  
@keyframes change{
    0%
    {
        background-image: url(../../assets/images/jagriti_img5.webp);
        
    }
    25%
    {
        background-image: url(../../assets/images/jagriti_img3.webp);
        
    }
    50%
    {
        background-image: url(../../assets/images/jagriti_img2.webp);
        
    }
    75%
    {
        background-image: url(../../assets/images/jagriti_img4.webp);
        
    }
    100%
    {
        background-image: url(../../assets/images/jagriti_img5.webp);
        
    }
   
}
