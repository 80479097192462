.preloader{
    height: 100vh;
    width: 100%;
    z-index: 5000;
    position: sticky;
    display: flex;
    justify-content: center;
    align-items: center;
}

.preloader >*{
    display: flex;
}