.button {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  padding: 10px 36px;
  border-width: 1.5px;
  border-radius: 8px;
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 500;
  width: 235px;
}

.normal_btn:hover {
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
}

.disabled_btn:hover {
  text-shadow: 0px 4px 15px rgba(0, 0, 0, 0.45);
}

.outline_btn:hover {
  text-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
}
