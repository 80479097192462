.home-page {
  /* width: 100vw; */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  /* overflow-x: unset; */
  
}
.timing{
    /* width: 100vw; */
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* overflow: auto; */
    overflow-x: hidden;
}
.jagriti-img {
  display: block;
  margin-left: auto;
  /* margin-top: 6%; */
  margin-right: auto;
  width: 30%;
  margin-bottom: 2rem;
  /* align-items: center; */
  /* justify-content: center; */
}
.videoPlay {
  position: absolute;
  top: 0;
  object-fit: cover;
  filter: blur(1px);
  height: 100vh;
  width: 100vw;
  z-index: -1;
  filter: brightness(0.6);
}
.explore-btn {
    display: flex;
    justify-content: center;
    gap: 1rem;
    align-content: center;
    flex-direction: column;
    align-items: center;
}
@media screen and (max-width: 1000px) {
    .jagriti-img {
        width: 35%;
    }
}
@media screen and (max-width: 800px) {
    .jagriti-img {
        width: 40%;
    }
}
@media screen and (max-width: 600px) {
    .jagriti-img {
        width: 45%;
    }
}
@media screen and (max-width: 480px) {
    .jagriti-img {
        width: 60%;
    }
    .explore-btn{
        display: grid;
        grid-template-columns: auto;
    }
}
@media screen and (max-width: 370px) {
    .jagriti-img {
        width: 68%;
    }
    
}
