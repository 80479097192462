
.fwrap {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.gwrap {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto auto;
  gap: 25px 75px;
  background: #ffffff;
  padding: 30px 50px;
  border-radius: 16px;
  box-shadow: 0px 12px 35px rgba(26, 88, 155, 0.25);
  /* height: 800px; */
}
.heading {
  grid-column: 1 / 3;
  grid-row: 1/2;
}
.ca_heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  letter-spacing: 0.08em;
  color: #1a589b;
  /* margin-bottom: 10px; */
  display: inline-block;
  margin-bottom: 10px;
}

.ca_subheading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.08em;
  color: #000000;
}

.ca_img {
  grid-column: 2/3;
  grid-row: 2/3;
  margin-bottom: 20px;
  width: 350px;
  display: flex;
  align-items: center;
}

.ca_form_div {
  grid-column: 1/2;
  grid-row: 2/4;
}

.ca_form {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 30px;
  height: 100%;
}

.ca_form input {
  background: #f5f5ff;
  border: none;
  /* margin-bottom: 30px; */
  width: 430px;
  height: 50px;
  padding-left: 10px;
}

.ca_form input[type=number]::-webkit-inner-spin-button, 
.ca_form input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

.ca_form textarea {
  background: #f5f5ff;
  border: none;
  /* margin-bottom: 30px; */
  width: 430px;
  height: 150px;
  resize: none;
}

.ca_form input::placeholder {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.08em;
  color: #9f9f9f;
}

.ca_form textarea::placeholder {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.08em;
  color: #9f9f9f;
}

.ca_form button {
  background: #1a589b;
  width: 430px;
  height: 50px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.08em;
  color: #ffffff;
}

.contact_details {
  grid-column: 2/3;
  grid-row: 3/4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
}

.ca_details {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 13px;
  margin-left: 20px;
  /* justify-content: center; */
  /* margin-bottom: 30px; */
}

.ca_details img {
  width: 35px;
}

.ca_details a {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 21px;
  letter-spacing: 0.08em;
  color: #000000;
  /* margin-top: 5px; */
}

.ca_details a:hover {
  color: #1a589b;
}

.socials {
  align-self: flex-end;
  margin-bottom: 40px;
  background-color: #1a589b;
  border-radius: 0px 0px 50px 0px;
  max-width: 55px;
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px 7px 40px 7px;
}

@media screen and (max-width: 770px) {
  .gwrap {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto auto auto;
    gap: 20px 0;
    padding: 30px 20px;
  }
  .heading {
    grid-row: 1/2;
    grid-column: 1/2;
  }
  .ca_img {
    grid-row: 2/3;
    grid-column: 1/2;
    width: 240px;
    height: 150px;
  }
  .ca_form_div {
    grid-row: 3/4;
    grid-column: 1/2;
  }
  .contact_details {
    grid-row: 4/5;
    grid-column: 1/2;
    gap: 13px;
    margin-top: 10px;
    margin-left: 0px;
  }
  .ca_heading {
    font-size: 15px;
    line-height: 17px;
    letter-spacing: 0.025em;
  }
  .ca_subheading {
    letter-spacing: 0.025em;
  }
  .ca_form {
    gap: 15px;
  }
  .ca_form input {
    width: 281px;
    height: 40px;
  }
  .ca_form textarea {
    width: 281px;
    height: 100px;
  }
  .ca_form button {
    width: 281px;
    height: 40px;
    font-size: 12px;
    line-height: 18px;
  }
  .ca_details {
    gap: 9px;
    margin-left: 0px;
  }

  .ca_details a {
    font-size: 10px;
    line-height: 15px;
  }
  .ca_details img {
    width: 20px;
  }
  .socials {
    margin-bottom: 0px;
    border-bottom-right-radius: 16px;
    justify-content: space-evenly;
    padding-top: 0px;
    padding-bottom: 0px;
    max-width: 40px;
  }
  .fwrap{
    display: block;
  }
}

@media screen and (min-width: 770px) and (max-width: 1050px) {
  .gwrap {
    column-gap: 40px;
    padding-left: 25px;
    padding-right: 22px;
  }
  .ca_form input,
  .ca_form textarea,
  .ca_form button {
    width: 350px;
  }
  .ca_details {
    gap: 10px;
    margin-left: 0px;
  }
  .ca_details a{
    font-size:18px;
  }
  .contact_details {
    gap: 20px;
    margin-right: 12px;
  }
  .ca_img{
    width: 250px;
  }
  .socials{
    padding-left: 3px;
    padding-right: 3px;
  }
}
