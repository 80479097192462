.skewed_bg {
  background: linear-gradient(260.35deg, #1a589b 13.84%, #1b78dc 89.85%);
  padding: 30vh 0;
  transform: skew(0deg, -10deg) translateY(-125px);
  position: relative;
  z-index: -1;
}
.MainEventCOntainer{
  background-color: lightblue;
}
.fwrap {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.gwrap {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto auto;
  gap: 25px 75px;
  background: #ffffff;
  padding: 30px 50px;
  border-radius: 16px;
  box-shadow: 0px 12px 35px rgba(26, 88, 155, 0.25);
  /* height: 800px; */
}
.heading {
  grid-column: 1 / 3;
  grid-row: 1/2;
}
.text_drive{
  position: absolute;
  top: 145px;
  left: 75px;
}
.text_url{
  position: absolute;
  top: 175px;
  left: 75px;
}
.listofnames {
  position: absolute;
  top: 230px;
  left: 80px;
  line-height: 26.4px;
  white-space: pre-wrap;
  color: black;
  font-weight: 900;
}
.whiteBox {
  background-color: white;
  box-shadow: 0px 10px 30px rgba(102, 106, 245, 0.13);
  width: 450px;
  height: 180px;
  border-radius: 3xl;
}
.event_heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  letter-spacing: 0.08em;
  color: #1a589b;
  /* margin-bottom: 10px; */
  display: inline-block;
  margin-bottom: 10px;
}

.event_subheading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.08em;
  color: #000000;
}

.event_img {
  grid-column: 2/3;
  grid-row: 2/3;
  margin-bottom: 20px;
  width: 350px;
  display: flex;
  align-items: center;
  justify-content: center; /* Horizontally center the content */
  margin: auto; /* Additional margin property for centering */
}

.event_form_div {
  grid-column: 1/2;
  grid-row: 2/4;
}

.event_form {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 30px;
  height: 100%;
}

.event_form input {
  background: #f5f5ff;
  border: none;
  /* margin-bottom: 30px; */
  width: 300px;
  height: 40px;
  padding-left: 10px;
}

.event_form input::placeholder {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.08em;
  color: #9f9f9f;
}

.event_form button {
  background: #1a589b;
  width: 430px;
  height: 50px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.08em;
  color: #ffffff;
}

.event_details {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 13px;
  margin-left: 20px;
  /* justify-content: center; */
  /* margin-bottom: 30px; */
}

.event_details img {
  width: 35px;
}

.event_details a {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 21px;
  letter-spacing: 0.08em;
  color: #000000;
  /* margin-top: 5px; */
}

.event_details a:hover {
  color: #1a589b;
}

.socials {
  align-self: flex-end;
  margin-bottom: 40px;
  background-color: #1a589b;
  border-radius: 0px 0px 50px 0px;
  max-width: 55px;
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px 7px 40px 7px;
}
@media screen and (max-width: 430px) {
  .gwrap {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto auto auto;
    margin-top: 80%;
    gap: 20px 0;
    /* padding: 30px 20px; */
  }
  .heading {
    grid-row: 1/2;
    grid-column: 1/2;
  }
  .event_img {
    grid-row: 2/3;
    grid-column: 1/2;
    width: 50px;
    height: 450px;
  }
  .event_form_div {
    grid-row: 3/4;
    grid-column: 1/2;
  }
  .text_drive{
    position: absolute;
    top: 10px;
    left: 20px;
  }
  .text_url{
    position: absolute;
    top: 50px;
    left: 20px;
  }
  .listofnames {
    position: absolute;
    top: 100px;
    left: 20px;
    line-height: 26.4px;
    white-space: pre-wrap;
    color: black;
    font-weight: 900;
  }
  .whiteBox {
    background-color: white;
    box-shadow: 0px 10px 30px rgba(102, 106, 245, 0.13);
    /* position: absolute;
    top: 25px; */
    position: relative;
    top: +400px;
    width: 150px;
    left: 5px;
    height: 20px;
    border-radius: 3xl;
  }
  .contact_details {
    display: none;
  }
  .event_heading {
    font-size: 15px;
    line-height: 17px;
    letter-spacing: 0.025em;
  }
  .event_subheading {
    letter-spacing: 0.025em;
  }
  .event_form {
    gap: 15px;
  }
  .event_form input {
    width: 281px;
    height: 40px;
  }
  .event_form textarea {
    width: 281px;
    height: 100px;
  }
  .event_form button {
    width: 281px;
    height: 40px;
    font-size: 12px;
    line-height: 18px;
  }
  .event_details {
    gap: 9px;
    margin-left: 0px;
  }

  .event_details a {
    font-size: 10px;
    line-height: 15px;
  }
  .event_details img {
    width: 20px;
  }
  .socials {
    display: none;
  }
  .fwrap{
    display: block;
  }
}

@media screen and (min-width: 430px) and (max-width: 770px) {
  .gwrap {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto auto auto;
    margin-top: 70%;
    gap: 20px 0;
    /* padding: 30px 20px; */
  }
  .heading {
    grid-row: 1/2;
    grid-column: 1/2;
  }
  .event_img {
    grid-row: 2/3;
    grid-column: 1/2;
    width: 50px;
    height: 200px;
  }
  .event_form_div {
    grid-row: 3/4;
    grid-column: 1/2;
  }
  .text_drive{
    position: absolute;
    top: 10px;
    left: 20px;
  }
  .text_url{
    position: absolute;
    top: 50px;
    left: 20px;
  }
  .listofnames {
    position: absolute;
    top: 100px;
    left: 20px;
    line-height: 26.4px;
    white-space: pre-wrap;
    color: black;
    font-weight: 900;
  }
  .whiteBox {
    background-color: white;
    box-shadow: 0px 10px 30px rgba(102, 106, 245, 0.13);
    /* position: absolute;
    top: 25px; */
    position: relative;
    top: -50px;
    width: 450px;
    height: 180px;
    border-radius: 3xl;
  }
  .contact_details {
    display: none;
    /* grid-row: 4/5;
    grid-column: 1/2;
    gap: 13px;
    margin-top: 10px;
    margin-left: 0px; */
  }
  .event_heading {
    font-size: 15px;
    line-height: 17px;
    letter-spacing: 0.025em;
  }
  .event_subheading {
    letter-spacing: 0.025em;
  }
  .event_form {
    gap: 15px;
  }
  .event_form input {
    width: 281px;
    height: 40px;
  }
  .event_form textarea {
    width: 281px;
    height: 100px;
  }
  .event_form button {
    width: 281px;
    height: 40px;
    font-size: 12px;
    line-height: 18px;
  }
  .event_details {
    gap: 9px;
    margin-left: 0px;
  }

  .event_details a {
    font-size: 10px;
    line-height: 15px;
  }
  .event_details img {
    width: 20px;
  }
  .socials {
    display: none;
    /* margin-bottom: 0px;
    border-bottom-right-radius: 16px;
    justify-content: space-evenly;
    padding-top: 0px;
    padding-bottom: 0px;
    max-width: 40px; */
  }
  .fwrap{
    display: block;
  }
}

@media screen and (min-width: 770px) and (max-width: 1050px) {
  .gwrap {
    column-gap: 40px;
    padding-left: 25px;
    padding-right: 22px;
  }
  .event_form input,
  .event_form textarea,
  .event_form button {
    width: 350px;
  }
  .event_details {
    gap: 10px;
    margin-left: 0px;
  }
  .event_details a{
    font-size:18px;
  }
  .contact_details {
    gap: 20px;
    margin-right: 12px;
  }
  .event_img{
    width: 250px;
  }
  .socials{
    padding-left: 3px;
    padding-right: 3px;
  }
}