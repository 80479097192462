body {
  background: #eee;
}

h2 {
  clear: both;
  font-size: 1.8em;
  margin-bottom: 10px;
  padding: 10px 0 10px 30px;
}

h3 > span {
  border-bottom: 2px solid #c2c2c2;
  display: inline-block;
  padding: 0 5px 5px;
}

/* USER PROFILE */
#user-profile h2 {
  padding-right: 15px;
}

#user-profile .profile-img {
  padding: 59px;
  margin-top: 10px;
  margin-bottom: 10px;
}
#user-profile .profile-label {
  text-align: center;
  padding: 5px 0;
}
#user-profile .profile-label .label {
  padding: 5px 15px;
  font-size: 1em;
}

#user-profile .profile-details {
  padding: 15px 0;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  margin: 15px 0;
}
#user-profile .profile-details ul {
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 40px;
}
#user-profile .profile-details ul > li {
  margin: 3px 0;
  line-height: 1.5;
}
#user-profile .profile-details ul > li > i {
  padding-top: 2px;
}

#user-profile .profile-header {
  position: relative;
}
#user-profile .profile-header > h3 {
  margin-top: 10px;
}
#user-profile .profile-header .edit-profile {
  margin-top: -6px;
  position: absolute;
  right: 0;
  top: 0;
}
#user-profile .profile-tabs {
  margin-top: 30px;
}
#user-profile .profile-user-info {
  padding-bottom: 20px;
}
#user-profile .profile-user-info .profile-user-details {
  position: relative;
  padding: 4px 0;
}
#user-profile
  .profile-user-info
  .profile-user-details
  .profile-user-details-label {
  width: 110px;
  float: left;
  bottom: 0;
  font-weight: bold;
  left: 0;
  position: absolute;

  top: 0;
  width: 110px;
  padding-top: 4px;
}
#user-profile
  .profile-user-info
  .profile-user-details
  .profile-user-details-value {
  margin-left: 120px;
}

@media only screen and (max-width: 767px) {
  #user-profile
    .profile-user-info
    .profile-user-details
    .profile-user-details-label {
    float: none;
    position: relative;
    text-align: left;
  }
  #user-profile
    .profile-user-info
    .profile-user-details
    .profile-user-details-value {
    margin-left: 0;
  }
  #user-profile .profile-social {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 420px) {
  #user-profile .profile-header .edit-profile {
    display: block;
    position: relative;
    margin-bottom: 15px;
  }
  #user-profile .profile-message-btn .btn {
    display: block;
  }
}

.main-box {
  background: #ffffff;
  -webkit-box-shadow: 1px 1px 2px 0 #cccccc;
  -moz-box-shadow: 1px 1px 2px 0 #cccccc;
  -o-box-shadow: 1px 1px 2px 0 #cccccc;
  -ms-box-shadow: 1px 1px 2px 0 #cccccc;
  box-shadow: 1px 1px 2px 0 #cccccc;
  margin-bottom: 16px;
  padding: 20px;
}
.main-box h2 {
  margin: 0 0 15px -20px;
  padding: 5px 0 5px 20px;
  border-left: 10px solid #c2c2c2; /*7e8c8d*/
}

.btnlogout {
  color: #ffffff !important;
  background-color: #1a589b !important;
  border-color: #1a589b !important;
}

.btn-danger {
  background-color: #fe635f;
  border-color: #dd504c;
}

.gborder {
  border-color: #1A589B;
}