.timer {
  /* width: 100vw; */
  height: 155px;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
}
.clock {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  /* width:350px; */
  gap:20px;
}
.clock small {
  color: #ffffff;
  font-size: 15px;
}
.clock p {
  color: #ffffff;
  font-size: 2rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
}
.text-small {
  display: flex;
  justify-content: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  margin-top: 2px;
}
.timer-count {
  width: 77px;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-right: 20px; */
  background: rgba(242, 224, 210, 0.35);
  border-radius: 12px;
}

@media screen and (max-width: 875px) {
  .timer-count-div:nth-child(4) {
    display: none;
  }
}
@media screen and (max-width: 350px) {
  .timer-count {
    padding: 1.3rem;
  }
  /* #hours {
    padding: 1.3rem;
  }
  #minutes {
    padding: 1rem;
  } */
}
