.bg {
  background: url('/public/assets/bg.png');
  background-size: cover;
  min-height: "210vh";
}

.clubBox {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  /* margin: 20px 20px; */
  justify-content: space-evenly;
}

.circular-container1 {
  position: relative;
  width: 300px; 
  height: 300px;  
  margin: auto;
  background: url('/public/assets/Circular1.png') center/cover no-repeat;
  background-position: 35% 35%;
  background-size: 180%;
  overflow: hidden; /* Crop the content outside the container */
}

.circular-container2 {
  position: relative;
  width: 300px; 
  height: 300px;  
  margin: auto;

  background: url('/public/assets/Circular2.png') center/cover no-repeat;
  background-position: 65% 65%;
  background-size: 180%;
  overflow: hidden; /* Crop the content outside the container */
}

.jaglogo-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-55%, -50%);
}

.ssclogo-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-45%, -45%);
}

.background-element{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('/public/assets/Vector.png') center/cover;
  z-index: -1;
  opacity: 0.35; /* Adjust the opacity as needed */
}