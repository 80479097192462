.navitem {
  position: relative;
  margin-left: 10px;
  color: #E5E7EB; /* text-gray-200 */
  letter-spacing: 1px; /* tracking-[1px] */
  font-weight: 800; /* font-extrabold */
  transition: color 0.3s;
}

.navitem::after {
  content: '';
  background-color: #93C5FD; /* bg-light-blue-800 */
  height: 3px;
  width: 0%;
  left: 0;
  bottom: -5px;
  border-radius: 8px; /* rounded-xl */
  position: absolute;
  transition: width 0.3s;
}

.navitem:hover::after {
  width: 100%;
}