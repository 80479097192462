.bg {
  background: url('/public/assets/bg.png');
  background-size: cover;
  min-height: "210vh";
}
.card:hover h1,
.card:hover p {
  opacity: 1;
}
.container_carousel {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Optional: adjust alignment as needed */
}
.scroll-icon {
  cursor: pointer;
  size: 50;
  padding-bottom: 2;
  /* Add additional styling as needed */
}
.galleryWrapper {
    padding: 1.0% 7%;
 }

.galleryItem img {
  width: 100%;
  height: 35vh;
  object-fit: cover;
  border-radius: 1rem;
}

.galleryContainer {
  display: flex;
  align-items:center;
  /* grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); */

  flex-wrap: wrap;
  justify-content:center;
  gap: 20px;
}
.carousel
{
  margin:  0 10px;
  overflow: hidden;
  white-space: nowrap;
  cursor: grab;
  
}

.inner-carousel {
  display: flex;
  
  /* height:6vh; */
  /* display: grid;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr)); */
  align-items: center;
  margin-bottom: 20px;
  gap: 10px;
}

.inner-carousel button {
  margin-right: 0.8rem;
  /* min-width: 5rem; */
  width: 100%;
  height: 100%;
  padding: 0.3rem 0.8rem;
  border: none;
  background: white;
  color: rgb(65, 98, 168);
  border-radius: 8px;
  font-family: "Poppins";
  border: 1.5px solid rgb(65, 98, 168);
  font-weight: 550;
  cursor: pointer;
 
}

/* .filterItem ul button:hover {
  background: rgb(65, 98, 168);
  border: 2px solid rgb(65, 98, 168);
  color: azure;
} */
.filterItem .selected {
  background: rgb(65, 98, 168);
  border: 2px solid rgb(65, 98, 168);
  color: azure;
}
.galleryItem img {
  border-radius: 15px;
}
.position-title {
  display: flex;
  justify-content: center;
  font-family: "Poppins";
  font-size: 1.2rem;
}
/* BUTTON */
.card {
  width: 230px;
  height: 265px;
  background: #07182e;
  border-radius: 5px;
  text-align: center;
  color: #f0f0f0;
  font-family: inherit;
  z-index: 1;
}
.img {
  padding: 1.5rem;
}
.img img {
  display: flex;
  width: 120px;
  height: 120px;
  margin: auto;
  border: 1px solid #f0f0f0;
  border-radius: 50%;
  font-size: 11px;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
  z-index: 9;
  background-color: #152f50;
}

.link-team {
  opacity: 0;
  transition: all 0.5s;
  display: flex;
  font-size: 1.3rem;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.heading {
  padding: 10px;
  font-weight: 500;
  font-size: 18px;
}

.card:hover h1,
.card:hover p {
  opacity: 1;
}
.our-team {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 4rem;
  /* font-family: "Poppins"; */
  padding-bottom: 1rem;
}
.our-team h1 {
  margin: 20px 0px;
  text-align: center;
  font-family: "Montserrat";
  /* font-style: normal; */
  font-weight: 700;
  font-size: 40px;
  line-height: 44px;
  color: #1a589b;
}
@media screen and (max-width: 450px) {
  .our-team {
    font-size: 2.5rem;
  }
}