.head {
  display: flex;
  justify-content: center;
}
.throwback{
  margin: 7% 0%;
}
.throwback_head p {
  margin: 30px 0px;
  text-align: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 44px;
  color: #1a589b;
}

.throwback-wrapper {
  display: flex;
  justify-content: space-around;
  padding: 0px 65px;
}

.throwback_img1 {
  align-self: flex-end;
}
.throwback_img1size {
  /* width: 1600px; */
  flex:2;
}

.right-wrapper {
  display: flex;
  flex-direction: column;
  flex:1;
}

.throwback_content {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #0b1641;
  text-align: center;
  /* flex:1; */
}
.throwback_img2{
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 5vh; */
}

/* .throwback_img2size {
  width: 440px;
  
} */

@media (max-width: 650px) {

  .throwback-wrapper {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
  }
  /* .throwback_content {
    order: 2;
  } */
  .throwback_img1 {
    order: 2;
    flex:2;
  }
  .right-wrapper {
    order: 1;
    flex:1;
  } 
  .throwback_content {
    font-size: 15px;
    line-height: 25px;
    text-align: start;
    padding-top: 10px;
    padding-bottom:10px;
    order:2;
    margin-bottom: 15px;
  }
  .throwback_img2size {
    order: 1;
    float: none;
    width: 250px;
    margin:0 auto;
  }
}

@media screen and (max-width:725px) and (min-width:650px) {
  .throwback-wrapper {
    display: flex;
    justify-content: space-around;
    padding: 0px 30px;
  }
  
  .throwback_img1{
    align-self: flex-end;
  }
  .throwback_img1size {
  width: 375px;
  }
  
  .right-wrapper {
    display: flex;
    flex-direction: column;
  }
  
  .throwback_content {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #0b1641;
    text-align: center;
    /* flex:1; */
  }
  
  .throwback_img2size {
    width: 150px;
    float: right;
  }

}



@media screen and (max-width:780px) and (min-width:725px) {
  .throwback-wrapper {
    display: flex;
    justify-content: space-around;
    padding: 0px 40px;
  }
  
  .throwback_img1{
    align-self: flex-end;
  }
  .throwback_img1size {
  width: 400px;
  }
  
  .right-wrapper {
    display: flex;
    flex-direction: column;
  }
  
  .throwback_content {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    color: #0b1641;
    text-align: center;
    /* flex:1; */
  }
  
  .throwback_img2size {
    width: 170px;
    float: right;
  }

}


@media screen and (max-width:880px) and (min-width:780px) {
  .throwback-wrapper {
    display: flex;
    justify-content: space-around;
    padding: 0px 30px;
  }
  
  .throwback_img1{
    align-self: flex-end;
  }
  .throwback_img1size {
  width: 425px;
  }
  
  .right-wrapper {
    display: flex;
    flex-direction: column;
  }
  
  .throwback_content {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    color: #0b1641;
    text-align: center;
    /* flex:1; */
  }
  
  .throwback_img2size {
    width: 190px;
    float: right;
  }

}





@media screen and (max-width:980px) and (min-width:880px) {
  .throwback-wrapper {
    display: flex;
    justify-content: space-around;
    padding: 0px 40px;
  }
  
  .throwback_img1{
    align-self: flex-end;
  }
  .throwback_img1size {
  width: 450px;
  }
  
  .right-wrapper {
    display: flex;
    flex-direction: column;
  }
  
  .throwback_content {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: #0b1641;
    text-align: center;
    /* flex:1; */
  }
  
  .throwback_img2size {
    width: 225px;
    float: right;
  }

}



@media screen and (max-width:1100px) and (min-width:980px) {
  .throwback-wrapper {
    display: flex;
    justify-content: space-around;
    padding: 0px 40px;
  }
  
  .throwback_img1{
    align-self: flex-end;
  }
  .throwback_img1size {
  width: 550px;
  }
  
  .right-wrapper {
    display: flex;
    flex-direction: column;
  }
  
  .throwback_content {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #0b1641;
    text-align: center;
    /* flex:1; */
  }
  
  .throwback_img2size {
    width: 250px;
    float: right;
  }

}

@media screen and (max-width:1300px) and (min-width:1100px) {
  .throwback-wrapper {
    display: flex;
    justify-content: space-around;
    padding: 0px 50px;
  }
  
  .throwback_img1{
    align-self: flex-end;
  }
  .throwback_img1size {
  width: 650px;
  }
  
  .right-wrapper {
    display: flex;
    flex-direction: column;
  }
  
  .throwback_content {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    color: #0b1641;
    text-align: center;
    /* flex:1; */
  }
  
  .throwback_img2size {
    width: 300px;
    float: right;
  }
}

/*@media (max-width: 414px) {
  .throwback_head p {
    margin: 30px 0px;
    text-align: center;

    font-family: "Montserrat";
    font-style: normal;
    font-weight: 550;
    font-size: 28px;
    line-height: 36px;
    color: #1a589b;
  }
  .throwback-wrapper {
    display: flex;
    flex-direction: column;
  }
  .throwback-wrapper:nth-child(1) {
    order: 2;
  }
  .throwback-wrapper:nth-child(2) {
    order: 1;
  }

  .right-wrapper:nth-child(1) {
    order: 2;
  }
  .right-wrapper:nth-child(2) {
    order: 1;
  }
}*/
